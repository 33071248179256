/**
 * FiltersQueryParams
 *
 * Uses query params to click appropriate filter on Shop page. If no matching
 * filter is specified here, no filter is clicked
 */
const FiltersQueryParams = () => {
  const params = new URLSearchParams(window.location.search)
  const filter = params.get('filter')

  if (!filter) return

  let filterToClick: HTMLInputElement | null = null

  if (filter === 'affordable') {
    // this is the '$' price tier:
    filterToClick = document.querySelector<HTMLInputElement>('[dk-filter-item-value="e5ddfbd86d61616214ff88e9049bf5a4"]')
  } else {
    filterToClick = document.querySelector<HTMLInputElement>(`[dk-filter-item-slug="${filter}"]`)
  }

  if (filterToClick) filterToClick.click()
}

export default FiltersQueryParams
